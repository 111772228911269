// src/components/Features.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Features.css';

const Features = () => (
  <div className="features-container">
    <section className="features-section py-5" aria-labelledby="features-title">
      <Container>
        <Row className="text-center">
          <Col>
            <h2 id="features-title" className="features-title">
              Aprendizaje <span className="highlight">en la nube.</span>
            </h2>
            <p className="features-description">
              ELEVA es una plataforma en línea que combina herramientas novedosas para llevar a cabo una educación exitosa.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={3} className="text-center feature-box mx-2">
            <div className="feature-icon-wrapper">
              <img
                src="/images/admifile.png"
                alt="Administración de cursos y contratos con ELEVA"
                className="feature-icon"
                loading="lazy"
              />
            </div>
            <div className="feature-content">
              <h3>Administración de Mentorías, Cursos y Capacitaciones</h3>
              <p>Inscribe y gestiona con facilidad tus horarios para dar mentorías, cursos, capacitaciones y paquetes educativos.</p>
            </div>
          </Col>
          <Col md={3} className="text-center feature-box mx-2">
            <div className="feature-icon-wrapper">
              <img
                src="/images/calendar.png"
                alt="Programación de clases y seguimiento de asistencia con ELEVA"
                className="feature-icon"
                loading="lazy"
              />
            </div>
            <div className="feature-content">
              <h3>Programación de Clases y Seguimiento de Asistencia</h3>
              <p>Organiza y reserva sesiones en línea, con seguimiento completo de la asistencia.</p>
            </div>
          </Col>
          <Col md={3} className="text-center feature-box mx-2">
            <div className="feature-icon-wrapper">
              <img
                src="/images/progress.png"
                alt="Seguimiento del progreso estudiantil con ELEVA"
                className="feature-icon"
                loading="lazy"
              />
            </div>
            <div className="feature-content">
              <h3>Seguimiento de Progreso</h3>
              <p>Evalúa y supervisa el avance estudiantil, ofreciendo retroalimentación personalizada.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default Features;
