import React, { useState, useEffect } from 'react'; 
import Header from './components/Header';
import Register from './components/Register';
import Hero from './components/Hero';
import Partners from './components/Partners';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import News from './components/News';
import Footer from './components/Footer';
import AboutEleva from './components/AboutEleva';
import Funciones from './components/Funciones';
import CookieBanner from './components/CookieBanner';
import CookiePolicyPage from './components/CookiePolicyPage';
import AboutUs from './components/AboutUs';
import { SessionProvider } from "next-auth/react";
import WelcomeBanner from './components/WelcomeBanner';
import { Modal, Button } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
// Importar Google Analytics
import { initGA, logPageView } from './analytics'; // Asegúrate de haber creado el archivo analytics.js

function App() {
  const [showCookies, setShowCookies] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCookiePolicyClick = () => {
    setShowCookies(true);
  };

  const handleCloseCookiePolicy = () => {
    setShowCookies(false);
  };

  const handleRegisterClick = () => {
    setShowRegister(true);
  };

  const handleCloseRegister = () => {
    setShowRegister(false);
  };

  const handleSuccessModalShow = () => {
    setShowSuccessModal(true);
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('status') === 'success') {
      handleSuccessModalShow();
    }
  }, []);

  // Inicializar Google Analytics
  useEffect(() => {
    initGA(); // Inicializa Google Analytics solo una vez
    logPageView(); // Registra la primera vista de página
  }, []);

  return (
    <SessionProvider>
      <Helmet>
        {/* SEO Avanzado */}
        <title>Eleva Bolivia - Mentoría Online para Impulsar tu Carrera Profesional</title>
        <meta name="description" content="Descubre cómo Eleva Bolivia conecta a profesionales y empresas con mentores expertos. Potencia tus habilidades profesionales y mejora tu empleabilidad con cursos online y asesoramiento personalizado." />
        <meta name="keywords" content="
          mentoría empresarial en Bolivia, plataforma de mentoría personalizada, habilidades profesionales en Bolivia, 
          capacitación laboral online, desarrollo de liderazgo en Bolivia, mejora de habilidades blandas, 
          innovación en el mercado laboral, capacitación en habilidades digitales, cierre de brecha laboral en Bolivia, 
          mentores empresariales, transformación digital en empresas, entrenamiento en habilidades interpersonales, 
          desarrollo de talento profesional, habilidades críticas para el futuro del empleo, automatización y empleabilidad, 
          soft skills más demandadas en Bolivia, educación continua para profesionales, formación en liderazgo y trabajo en equipo, 
          mentoría para emprendedores, SaaS para el desarrollo profesional, capacitación en empresas bolivianas, 
          futuro del trabajo en Bolivia, empleabilidad y desarrollo profesional en LATAM, formación profesional a distancia, 
          desarrollo de competencias laborales, mentoría profesional, habilidades laborales, capacitación online, 
          formación en liderazgo, desarrollo de habilidades blandas, plataforma de mentoría, mejora de empleabilidad, 
          mentores empresariales, capacitación para empresas, innovación en el trabajo
        " />
        <meta name="author" content="Eleva Bolivia" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical" href="https://www.elevabo.com" />

        {/* Open Graph para Redes Sociales */}
        <meta property="og:title" content="Eleva Bolivia - Mentoría Online para Impulsar tu Carrera Profesional" />
        <meta property="og:description" content="Conecta con mentores expertos y desarrolla tus habilidades profesionales con nuestros cursos online. Accede a capacitación de alto nivel en Bolivia." />
        <meta property="og:image" content="https://elevabo.com/images/logo_eleva.png" />
        <meta property="og:url" content="https://www.elevabo.com" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Eleva Bolivia" />
        <meta property="og:locale" content="es_ES" />

        {/* Twitter Card para Compartir */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Eleva Bolivia - Mentoría Online para Impulsar tu Carrera Profesional" />
        <meta name="twitter:description" content="Conecta con mentores expertos y desarrolla tus habilidades profesionales con nuestros cursos online. Accede a capacitación de alto nivel en Bolivia." />
        <meta name="twitter:image" content="https://elevabo.com/images/logo_eleva.png" />
        <meta name="twitter:site" content="@elevabolivia" />
        <meta name="twitter:creator" content="@elevabolivia" />

        {/* Idioma y Compatibilidad */}
        <html lang="es" />
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* JSON-LD Schema.org para LocalBusiness */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Eleva Bolivia",
              "url": "https://www.elevabo.com",
              "logo": "https://elevabo.com/images/logo_eleva.png",
              "description": "Eleva Bolivia ofrece mentoría y cursos online para impulsar tu carrera profesional.",
              "telephone": "+591 75514502",
              "email": "info@elevabo.com",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Santa Cruz",
                "addressCountry": "BO"
              },
              "openingHours": "Mo-Fr 09:00-18:00",
              "priceRange": "$$",
              "sameAs": [
                "https://www.facebook.com/elevabolivia",
                "https://www.instagram.com/elevabolivia"
              ],
              "hashtag": [
                "#MentoríaBolivia", "#CapacitaciónProfesional", "#HabilidadesBlandas", 
                "#DesarrolloEmpresarial", "#Empleabilidad", "#Liderazgo", 
                "#FormaciónContinua", "#CrecimientoProfesional", "#Emprendimiento", "#InnovaciónLaboral"
              ]
            }
          `}
        </script>

      </Helmet>




      <div>
        <WelcomeBanner />
        <Header onRegisterClick={handleRegisterClick} />
        <Hero />
        <Features />
        <AboutEleva onRegisterClick={handleRegisterClick} />
        <Funciones />
        <News />
        {/* <Partners /> */}
        <Footer onCookiePolicyClick={handleCookiePolicyClick} />
        <CookieBanner onCookiePolicyClick={handleCookiePolicyClick} />

        {showCookies && (
          <div className="cookies-modal">
            <div className="cookies-modal-content">
              <button className="close-button" onClick={handleCloseCookiePolicy}>Cerrar</button>
              <CookiePolicyPage />
            </div>
          </div>
        )}

        {showRegister && <Register onClose={handleCloseRegister} onRegisterSuccess={handleSuccessModalShow} />}

        <Modal show={showSuccessModal} onHide={handleSuccessModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>¡Registro Exitoso!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¡Gracias por registrarte a Eleva! Pronto recibirás más noticias.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSuccessModalClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </SessionProvider>
  );
}

export default App;
