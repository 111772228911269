import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import AboutUs from './AboutUs'; // Se mantiene el modal original
import './Header.css';

const Header = () => {
  const [showAboutUs, setShowAboutUs] = useState(false);

  const handleAboutClick = (e) => {
    e.preventDefault();
    setShowAboutUs(true);
  };

  const closeAboutModal = () => {
    setShowAboutUs(false);
  };

  return (
    <>
      <Navbar expand="md" className="header">
        {/* LOGO MÓVIL (SOLO EN PANTALLAS PEQUEÑAS) */}
        <div className="mobile-logo-container d-block d-md-none">
          <img
            src="/images/elogo.png"
            alt="ELEVA Mobile Logo"
            className="mobile-logo"
            loading="lazy"
          />
        </div>

        {/* Navbar Toggle Button (Solo visible en móvil) */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="d-md-none navbar-toggler-custom"
        />

        {/* Logo de escritorio (oculto en móvil) */}
        <Navbar.Brand
          href="https://app.elevabo.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="my-0 d-none d-md-block desktop-logo"
        >
          <img
            src="/images/logo_eleva.png"
            alt="ELEVA Bolivia - Mentoría empresarial y capacitación online"
            className="logo"
            loading="lazy"
          />
        </Navbar.Brand>

        {/* Menú de Navegación (colapsable) */}
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="align-items-center">
            <Nav.Link
              href="https://app.elevabo.com/"
              title="Inicio - ELEVA Bolivia"
              target="_blank"
              rel="noopener noreferrer"
            >
              Inicio
            </Nav.Link>
            <Nav.Link
              href="https://app.elevabo.com/home/courses"
              title="Cursos de mentoría profesional"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cursos
            </Nav.Link>
            <Nav.Link
              href="https://app.elevabo.com/blog"
              title="Blog sobre habilidades profesionales"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </Nav.Link>
            {/* Este enlace abre un modal, no necesita target="_blank" */}
            <Nav.Link
              href="#about"
              onClick={handleAboutClick}
              title="Sobre ELEVA - Plataforma de mentoría personalizada"
            >
              Sobre Nosotros
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {/* Botones de Sesión (fuera del toggle bar) */}
        <div className="auth-buttons">

          <a
            href="https://app.elevabo.com/login"
            className="btn-login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Iniciar Sesión
          </a>
          <a
            href="https://app.elevabo.com/sign_up"
            className="btn-register"
            target="_blank"
            rel="noopener noreferrer"
          >
            Regístrate
          </a>
        </div>
      </Navbar>

      {/* Modal para Sobre Nosotros (usando el modal original) */}
      {showAboutUs && (
        <div className="modal-overlay" onClick={closeAboutModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-button" onClick={closeAboutModal}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <AboutUs />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
