// src/components/Hero.js
import React, { useState, useRef } from 'react';
import { Container, Row, Col, Button, Modal, Overlay } from 'react-bootstrap';
import './Hero.css';

const Hero = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [showBubble, setShowBubble] = useState(false);
  const futureButtonRef = useRef(null);

  const handleVideoOpen = () => setShowVideo(true);
  const handleVideoClose = () => setShowVideo(false);

  // NUEVA función para abrir ELEVA en una nueva pestaña
  const handleOpenElevabo = () => {
    window.open('https://app.elevabo.com/', '_blank', 'noopener,noreferrer');
  };

  // Se mantiene la función del bubble, aunque ya no se use en el botón
  const handleFutureClick = (e) => {
    e.preventDefault();
    setShowBubble(!showBubble);
  };

  return (
    <>
      {/* Sección Hero */}
      <section className="hero-section text-center py-5" role="banner">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="text-md-left">
              <h1 className="hero-title">
                <span className="hero-main-title">Estudiar</span>{' '}
                <span className="hero-main-title-secondary">en línea ahora</span><br/>
                <span className="hero-sub-title">es mucho más fácil.</span>
              </h1>
              <p>ELEVA es una plataforma innovadora que te enseñará de manera más interactiva.</p>
              <div className="button-container">
                {/* BOTÓN MODIFICADO: Emprende tu futuro */}
                <Button
                  variant="primary"
                  className="mr-2 custom-button"
                  ref={futureButtonRef}
                  onClick={handleOpenElevabo} // Abre ELEVA en nueva pestaña
                  aria-label="Emprende tu futuro"
                >
                  Emprende tu futuro
                </Button>

                {/* Botón para ver video */}
                <Button
                  className="custom-button play-button"
                  onClick={handleVideoOpen}
                  aria-label="Mira cómo funciona"
                >
                  <img
                    src="/images/playbutton.png"
                    alt="Botón de play para ver el video de cómo funciona ELEVA"
                    className="play-icon"
                    loading="lazy"
                  />
                </Button>
                <span className="play-text">Mira cómo funciona</span>
              </div>
            </Col>

            <Col md={6} className="d-none d-md-block">
              <div className="hero-image-container">
                <img
                  src="/images/headerback3.png"
                  alt="Estudiante usando la plataforma ELEVA"
                  className="hero-image"
                  loading="lazy"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Burbuja para "Emprende tu futuro" (el overlay permanece, aunque ya no se active en el botón) */}
      <Overlay
        target={futureButtonRef.current}
        show={showBubble}
        placement="bottom"
        containerPadding={20}
        rootClose={true}
        onHide={() => setShowBubble(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            className="bubble"
            {...props}
            style={{ ...props.style }}
          >
            ¡Próximamente!
          </div>
        )}
      </Overlay>

      {/* Modal para el video */}
      <Modal
        show={showVideo}
        onHide={handleVideoClose}
        centered
        size="lg"
        contentClassName="video-modal"
        dialogClassName="video-modal-dialog"
      >
        <Modal.Body className="p-0">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/gM0LYuMWnmA?autoplay=1&rel=0&showinfo=0&controls=0"
              title="ELEVA | Tu conexión directa con mentores para transformar tu futuro"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Hero;
